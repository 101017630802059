import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import Scrollspy from 'react-scrollspy';
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';


import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'What is This “Sacrifice” Thing?',
        description: 'Sacrifice is now ongoing since March 7th, 00:00 UTC.<br>We are currently on Day 128',
        buttonText: 'Read more',
        buttonLink: '/contact'
    }
]

class MainDemo extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);       
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="POWERCITY" />
                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-powercity.gif" alt="Powercity"/>
                                    <img className="logo-2" src="/assets/images/logo/pc-logo-icon.gif" alt="Powercity"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','about', 'apps', 'team','sacrifice','docs']} currentClassName="is-current" offset={-200}>
                                <li><a href="/#home">Home</a></li>
                                <li><a href="/#about">About</a></li>
                                <li><a href="/#apps">Apps</a></li>
                                <li><a href="/#team">Team</a></li>
                                <li><a href="/#sacrifice">Sacrifice</a></li>
                                <li><a href="/#docs">Docs</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="btn-default size-md btn-border btn-opacity" target="_blank" href="https://powercity.io/corebuildv1">
                                    <span>Connect</span>
                                </a>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div id="sacrifice" className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            
                                            <h1 className="title theme-gradient">What is This<br/> “Sacrifice” Thing?</h1>
                                            <p className="description">Sacrifice is now ongoing since March 7th, 00:00 UTC.<br/>
                                            We are currently on <strong>Day 128</strong></p>
                                            [timer]
                                            <p className="description">Total sacrificed balance so far: <strong>$2,129,628</strong><br/>How does Sacrifice Work?</p>
                                            <div className="slide-btn"><ScrollLink activeclassName="active" className="btn-default btn-solid color-sac btn-opacity" to="more" smooth={true} offset={0} duration={500}>Read more</ScrollLink></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--8 fix">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div id="more" className="service-area ptb--120 bg_color--sac">
                   <div className="container">
                        {/* Start Columns Area  */}
                       
                          
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="single-column">
                                            <h3 className="tilte">What is This “Sacrifice” Thing?</h3>
                                            <p>You are sacrificing to prove how strongly you believe in a healthy, vibrant, and competitive market of products - You believe in the Right to Free Enterprise! The set of people who sacrifice to show their commitment to this political statement should have no expectation of profit from the work of others. You are not buying anything. The world might notice you are amongst a group of people who strongly believe in a free market, a group that would appreciate the value of things designed to promote or support free markets.</p>

                                            <p>Suppose the world allocated points, think of them as a smile or attaboy points, to these sacrifices. In that case, they are not meant to have any monetary value, be represented as any tokens in a wallet, nor represent a promise of value.</p>

                                            <p>If you choose to sacrifice you must have no expectation of profit derived from the work of others.</p>


                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                        <div className="single-column">
                                            <h3 className="tilte">How does Sacrifice Work?</h3>
                                            <p>To sacrifice your crypto as a political statement, you must give your crypto away. Once you have surrendered or sacrificed your crypto, you no longer own it nor expect anything in return. You are not buying any tokens. Wherever your crypto lands, whoever takes ownership and control, does not work for you and has no obligations. Never expect profit from the work of others.</p>
                                            <h3>Points</h3>
                                            <p>Sacrifices might be credited sacrifice points based on sacrificing to the specified wallet. Those points do not have value. In the future, someone may decide to mint WATT tokens from the POWERCITY CORE based on the number of points an address is allotted.</p>

                                            <p>For the first 16 days of the sacrifice phase, recognized sacrifices might be allotted a decreasing amount of sacrifice points according to the table per each $1 USD value sacrificed. On day 17 and through the launch of PulseChain, 1 sacrifice point will be allotted per each $1 USD value sacrificed.</p>

                                            <p>The price at the time of the transaction as reported by the chain’s block explorer could define USD value.</p>
                                        </div>
                                    </div>
                                </div>

                               
                      
                        {/* Start Columns Area  */}
                   </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div id="team" className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Experts growts</span>
                                    <h2 className="title">Our Company Growth</h2>
                                    <p className="description">We have grown strength over the past 20 years.</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">what's stories on</span>
                                    <h2 className="title">Latest News</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">Read More</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Top clients</span>
                                    <h2 className="title">We worked with brands.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;