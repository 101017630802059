import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component{
    render(){
       

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="https://www.assuredefi.io/wp-content/uploads/2022/03/POWERCITY-NFT.gif" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                      
                                        <h2 className="title">POWERCITY has successfully been <a target="_blank" href="https://www.assuredefi.io/projects/powercity/">KYC'd by Assure</a>.</h2>
                                        <p className="description">KYC is privately identifying project teams with a thorough and secure KYC provider, then validate the KYC approval publicly by putting it on the blockchain with a NFT for the PowerCity community to verify.</p>

                                        <div className="button-group mt--50">
                                            <a className="btn-default color-sac" href="https://medium.com/assure-defi/kyc-by-assure-e276cb5f192c">Learn more</a>
                                            <a className="btn-default btn-border color-sac" href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38866644768768473940932911596864784329968136570494593720793432373190400671745">NFT</a>                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;