import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">                    
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                        <p>Solidity</p>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                        <p>Hardhat</p>                    
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                        <p>React.js</p>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                        <p>Ethers.js</p>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                        <p>PulseChain</p>                        
                    </li>                  
                   
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;