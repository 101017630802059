import React, { Component, Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { FiHeart, FiClock, FiCheckCircle, FiAward } from "react-icons/fi";
import axios from "axios";

function CounterOne() {
  const [marketCap, setMarketCap] = useState(0);

  const getMarketCap = async () => {
    let config = {
      method: "get",
      url: "https://2cqz6t11blve.powercity.io/?token=marketcap&perunit=minute",
      headers: {
        accept: "application/json",
      },
    };
    let response = await axios(config);
    let lastItem = response.data.pop();
    setMarketCap(Number(lastItem.MarketCap).toFixed(8));
  };

  const [rewardPool, setRewardPool] = useState(0);

  const getRewardPool = async () => {
    let config = {
      method: "get",
      url: "https://2cqz6t11blve.powercity.io/?token=rewardtotal&perunit=minute",
      headers: {
        accept: "application/json",
      },
    };
    let response = await axios(config);
    let lastItem = response.data.pop();
    setRewardPool(Number(lastItem.Total).toFixed(8));
  };

  const [wattPrice, setWattPrice] = useState("0.1");

  const getWattPrice = async () => {
    let config = {
      method: "get",
      url: "https://2cqz6t11blve.powercity.io/?token=watt&perunit=minute",
      headers: {
        accept: "application/json",
      },
    };
    let response = await axios(config);
    let lastItem = response.data.pop();
    setWattPrice(Number(lastItem.Price).toFixed(8));
  };

  const [lockedWatt, setlockedWatt] = useState("0.1");

  const getLockedWatt = async () => {
    let config = {
      method: "get",
      url: "https://2cqz6t11blve.powercity.io/?token=watt_locked&perunit=minute",
      headers: {
        accept: "application/json",
      },
    };
    let response = await axios(config);
    let lastItem = response.data.pop();
    setlockedWatt(Number(lastItem["Locked WATT"]).toFixed(0));
  };

  getWattPrice();
  getLockedWatt();
  getRewardPool();
  getMarketCap();

  // polling
  useEffect(() => {
    const interval = setInterval(() => {
      getWattPrice();
      getLockedWatt();
      getRewardPool();
      getMarketCap();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  let Data = [
    {
      icon: <FiHeart />,
      pre: "", // $1.0'
      countNum: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 8,
        minimumFractionDigits: 0,
      }).format(wattPrice), //wattPrice,
      unit: "",
      countTitle: "$WATT Price",
    },

    {
      icon: <FiClock />,
      pre: "", // $1.0'
      countNum: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(marketCap),
      unit: "", //M
      countTitle: "Market Cap",
    },

    {
      icon: <FiCheckCircle />,
      countNum: new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(Math.ceil(Number(lockedWatt))), //lockedWatt,
      unit: "", // %
      countTitle: "Total Locked WATT",
    },

    {
      icon: <FiAward />,
      pre: "", // $1.0'
      countNum: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(rewardPool),
      unit: "", // M
      countTitle: "Reward Pool",
    },
  ];

  return (
    <Fragment>
      <div className="row">
        {Data.map((value, index) => (
          <div
            className="im_single_counterup col-lg-3 col-md-6 col-xs-6"
            key={index}>
            <div className="im_counterup">
              <div className="inner">
                {/* <div className="icon">
                                        {value.icon}
                                    </div> */}
                <h2 className="counter">
                  {value.pre} {value.countNum} {value.unit}
                  {/* <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                            <CountUp end={this.state.didViewCountUp ? value.countNum : "?"} />
                                        </VisibilitySensor>{value.unit} */}
                </h2>
                <p className="description">{value.countTitle}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default CounterOne;
