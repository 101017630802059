import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  FaTwitter,
  FaTelegramPlane,
  FaDiscord,
  FaYoutube,
  FaGithub,
  FaMedium,
} from "react-icons/fa";
import { ProgressBar } from "react-bootstrap";
const SocialShare = [
  {
    Social: <FaTelegramPlane />,
    link: "https://t.me/POWERCITYio/",
    text: "Telegram",
  },
  {
    Social: <FaDiscord />,
    link: "https://discord.gg/w574jPnQpZ",
    text: "Discord",
  },
  {
    Social: <FaTwitter />,
    link: "https://twitter.com/POWERCITYio/",
    text: "Twitter",
  },
  {
    Social: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCKoFHpAen2bjhqp4Vzq6ZRQ",
    text: "YouTube",
  },
  { Social: <FaGithub />, link: "#", text: "GitHub" },
  {
    Social: <FaMedium />,
    link: "https://medium.com/@powercity",
    text: "Medium",
  },
];

class TabsTwo extends Component {
  render() {
    let tab1 = "POWERCITY CORE",
      tab2 = "Development Status",
      tab3 = "Roadmap",
      tab4 = "Socials";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <br />
                      <p>
                        Stake $WATT in the CORE to earn a share of revenue
                        generated from the full POWERCITY ecosystem of dApps and
                        services.
                      </p>

                      <ul>
                        <li>
                          staking rewards paid in $PLS, $PLSX, $HEX, $PXDC,
                          $HEXDC and more
                        </li>
                        <li>
                          stake NFTs can be transferred or resold without
                          unstaking
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <div
                        className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                        <div className="single-progress">
                          <h6 className="title">CORE</h6>
                          <ProgressBar now={100} />
                          <span className="label">100%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">PortalX Cross-Chain Swap</h6>
                          <ProgressBar now={100} />
                          <span className="label">100%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">EARN Protocol </h6>
                          <ProgressBar now={100} />
                          <span className="label">100%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">FLEX Protocol </h6>
                          <ProgressBar now={100} />
                          <span className="label">100%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">Amplifier</h6>
                          <ProgressBar now={100} />
                          <span className="label">100%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">PixelPark NFT Marketplace</h6>
                          <ProgressBar now={90} />
                          <span className="label">90%</span>
                        </div>

                        <div className="single-progress">
                          <h6 className="title">Accelerator</h6>
                          <ProgressBar now={0} />
                          <span className="label">TBA</span>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <strong>LIVE</strong>
                          <br />
                          - PortalX Cross-Chain Swap
                          <br />
                          - POWERCITY CORE
                          <br />
                          - EARN Protocol
                          <br />- FLEX Protocol
                        </li>
                        <li>
                          <strong>Q3 2024</strong>
                          <br />- Amplifier Yield Compounder
                          <br />- PixelPark NFT Marketplace
                        </li>
                        <li>
                          <strong>Beyond</strong>
                          - Accelerator Yield Compounder
                          <br />- More!
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <div className="social-share-inner d-none d-sm-block">
                        <ul className="social-share justify-content-start liststyle">
                          {SocialShare.map((val, i) => (
                            <li key={i}>
                              <a href={`${val.link}`}>
                                {val.Social} <span>{val.text}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
