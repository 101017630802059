import React, { Component } from "react";
import { Link } from "react-router-dom";
const Portfolio_image = (
  <img src="/assets/images/portfolio/portfolio-8.jpg" alt="PixelPark" />
);
const Portfolio_image2 = (
  <img src="/assets/images/portfolio/portfolio-9.jpg" alt="PixelPark" />
);
const Portfolio_image3 = (
  <img src="/assets/images/portfolio/portfolio-10.jpg" alt="PixelPark" />
);
const pixelparklogo = (
  <img
    className="pixelpark-logo"
    src="/assets/images/logo/pixelpark.jpg"
    alt="PixelPark"
  />
);

const PortfolioList = [
  {
    image: Portfolio_image,
    category: "Walrus Wharf Ballers Club #396",
    title: "625 PXDC or 2,500,000 PLS",
    description: "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.",
  },
  {
    image: Portfolio_image2,
    category: "Grim Legion #666",
    title: "500 PXDC or 2,000,000 PLS",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
  {
    image: Portfolio_image3,
    category: "PulseBombs #72",
    title: "375 PXDC or 1,500,000 PLS",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
];

class Portfolio extends Component {
  render() {
    let title = "Latest NFT's from PixelPark",
      description =
        "PixelPark plays an important role in POWERCITY protocols, such as the metaverse play-to-earn ecosystem.";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  {/* {pixelparklogo} */}
                  <br />
                  <h2 className="title">{title}</h2>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="row mt--30">
              {PortfolioList.map((value, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={index}
                >
                  <div className="im_portfolio">
                    <div className="thumbnail_inner">
                      <div className="thumbnail">
                        <Link to="#">{value.image}</Link>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="portfolio_heading">
                          <div className="category_list">{value.category}</div>
                          <h4 className="title">{value.title}</h4>
                        </div>
                        <div className="portfolio_hover">
                          <div className="button-group mt--50">
                            <a
                              className="btn-default btn-dark size-sm"
                              href="#"
                            >
                              Buy now
                            </a>
                            <a
                              className="btn-default btn-dark btn-border size-sm"
                              href="#"
                            >
                              See on PixelPark
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link className="transparent_link" to="#"></Link>
                  </div>
                </div>
              ))}
            </div>
            <br />
            {/* <a className="btn-default size-md btn-border pxprk_btn" href="#">Go to PixelPark</a> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
