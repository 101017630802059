import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "Roads?
                          <br /> Where we are going, we don't need roads!"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>TheLongVacation</span> - Founder -{" "}
                          <a
                            target="_blank"
                            href="https://t.me/TheLongVacation">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "Human advancement through interconnected
                          individualism and sustainability reimagined."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>ToshiFlo</span> - Founder -{" "}
                          <a target="_blank" href="https://t.me/ToshiFlo">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "And those who were seen dancing were thought to be
                          insane by those that couldn't hear the music."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>PulseJesus</span> - Founder -{" "}
                          <a target="_blank" href="https://t.me/cryptobangin">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "For millions of years the ability to harness energy
                          has been sought after. Use harnessed power wisely, and
                          become legend. Used uncontrollably and well ... don't
                          become Electrifried."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Jesse</span> - Founder -{" "}
                          <a target="_blank" href="https://t.me/JustAskJesse">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>"A witty saying proves nothing. I do tech stuff."</p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Tuba Terminator</span> - Lead Developer -{" "}
                          <a target="_blank" href="https://t.me/0xtubanator">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "Code is like humor. When you have to explain it, it's
                          bad. Yeet it!"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Simon</span> - Front-End Developer -{" "}
                          <a target="_blank" href="https://t.me/codeninja_1314">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "The measure of a leader is not the number of people
                          who serve him but the number of people he serves."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Reas </span> -{" "}
                          <a target="_blank" href="https://t.me/Reas035">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "Agriculture, manufacturers, commerce and navigation,
                          the four pillars of our prosperity, are the most
                          thriving when left most free to individual
                          enterprise."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Ivan</span> -{" "}
                          <a target="_blank" href="https://t.me/Timedeposit">
                            <FaTelegramPlane />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                          "Anybody can be God. All it takes is to make people
                          believe it."
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Shinto</span> - TechGod{" "}
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>“All problems are temporary, as is life itself”</p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Matt Cho</span>{" "}
                          <a
                            target="_blank"
                            href="https://twitter.com/M8Miyamoto">
                            <FaTwitter />
                          </a>
                        </h6>
                      </div>
                    </div>
                  </TabPanel>
                </div>
                <div className="col-lg-6 mt_md--40 mt_sm--40">
                  <TabList className="testimonial-thumb-wrapper">
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-1.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-2.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-3.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-4.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-5.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-6.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>

                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-11.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-12.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-16.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-18.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                    {/* <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src="/assets/images/client/testimonial-18.jpg"
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab> */}
                  </TabList>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
