import React, { Component, useState } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiMail,
  FiCopy,
  FiZap,
  FiRepeat,
  FiImage,
  FiTrendingUp,
  FiHexagon,
  FiSliders,
  FiShare2,
  FiX,
} from "react-icons/fi";
import Modal from "react-modal";
import core from "../../../public/assets/images/service/coremain.jpg";
import portalx from "../../../public/assets/images/service/portalx.jpg";
import pixelpark from "../../../public/assets/images/service/pixelpark.jpg";
import amplifier from "../../../public/assets/images/service/amplifier.jpg";
import earn from "../../../public/assets/images/service/earn.jpg";
import flex from "../../../public/assets/images/service/flex.jpg";

function ServiceTwo() {
  const ServiceList = [
    {
      icon: <FiZap />,
      title: "POWERCITY CORE",
      description:
        "The POWERCITY CORE is the staking platform at the center of the ecosystem.",
      long: "The POWERCITY CORE is the staking platform at the center of the ecosystem. Both $WATT and $WATT/$WPLS LP tokens can be staked, without any time-locks, to earn rewards in $WPLS or $PLSX coins and other tokens collected as fees within the ecosystem.  The fees generated by all the DAPPs and services within the POWERCITY ecosystem will be shared with the CORE's treasury and distributed to staked $WATT in the CORE.  Stakes will be represented as a receipt NFT that can be resold on PixelPark NFT Marketplace without having to unstake.",
      image: core,
      dapplink: "https://core.powercity.io/",
      morelink: "https://docs.powercity.io/core/",
      id: "core",
      launchbtn: "Launch",
    },
    {
      icon: <FiRepeat />,
      title: "PortalX Swap",
      description:
        "A cross-chain swap connecting 65+ chains, 60+ DEXes and 15,000+ tokens",
      long: "PortalX simplifies swapping tokens across different blockchain networks by connecting many bridges and decentralized exchanges together.  The optimal route is automatically mapped, allowing you to swap across blockchains and DEXs in a single transaction.  PortalX connects over 15,000 tokens and over 65 blockchains.",
      image: portalx,
      dapplink: "https://portalxswap.io/",
      morelink: "https://docs.powercity.io/portalx/",
      id: "swap",
      launchbtn: "Launch",
    },

    {
      icon: <FiShare2 />,
      title: "EARN Protocol",
      description:
        "An immutable decentralized lending protocol specifically for PulseX on PulseChain. Zero interest and repayment schedules.",
      long: "EARN Protocol is a truly decentralized lending protocol built specifically for the PulseX DEX token on PulseChain. EARN allows interest-free loans in the PXDC USD value-pegged stablecoin using PLSX as collateral.  Loans are secured by a Stability Pool containing PXDC while EARN Tokens can be staked to earn lending fees.",
      image: earn,
      dapplink: "https://earn.powercity.io/",
      morelink: "https://docs.powercity.io/earn-protocol/",
      id: "earn",
      launchbtn: "Launch",
    },
    {
      icon: <FiShare2 />,
      title: "FLEX Protocol",
      description:
        "An immutable decentralized lending protocol specifically for $HEX on PulseChain. Zero interest and repayment schedules.",
      long: "FLEX Protocol is a truly decentralized lending protocol built specifically for the HEX token on PulseChain. FLEX allows interest-free loans in the HEXDC USD value-pegged stablecoin using HEX as collateral. Loans are secured by a Stability Pool containing HEXDC while FLEX Tokens can be staked to earn lending fees.",
      image: flex,
      dapplink: "https://flex.powercity.io/",
      morelink: "https://docs.powercity.io/flex-protocol",
      id: "flex",
      launchbtn: "Launch",
    },
    {
      icon: <FiSliders />,
      title: "Amplifier",
      description:
        "A custom front-end to the EARN, Liquid Loans and Liquity lending protocols.",
      long: "Amplifier is an automated front-end to the EARN Protocol and Liquid Loans. These trustless crypto lending protocols mint a stablecoin by locking up crypto assets as collateral. Amplifier simplifies the operation of minting and staking while also automating several compounding strategies so you don’t need to continually track market prices to time the strategies properly.",
      image: amplifier,
      dapplink: "https://amplifier.powercity.io/",
      morelink: "https://docs.powercity.io/amplifier/",
      id: "amplifier",
      launchbtn: "Launch",
    },
    {
      icon: <FiImage />,
      title: "PixelPark NFT Marketplace",
      description:
        "Create, auction, purchase and launch NFTs on a marketplace that supports creators and influencers.",
      long: "PixelPark will be an exciting new NFT marketplace exclusive to PulseChain using the ERC-721 and ERC-1155 token standards. This versatile marketplace will allow users to create and launch digital works and purchase, trade and stake NFT’s. PixelPark will open the door for vibrant economies on PulseChain by offering the best non-fungible tools possible. PixelPark will also play an important role in the POWERCITY ecosystem, as well as the POWERCITY play-to-earn (P2E) Metaverse.",
      image: pixelpark,
      dapplink: "",
      morelink: "https://docs.powercity.io/ecosystem/pixelpark-nft-marketplace",
      id: "pixel",
    },
    
    // {
    //   icon: <FiTrendingUp />,
    //   title: "Accelerator",
    //   description:
    //     "Accelerate your yield in DeFi by claiming and redeploying earned tokens for increased rewards.",
    //   long: "This tool will increase the profitability of defi investments by automating the process of compounding yield by claiming rewards and restaking them to boost returns.  Compounding automation may be implemented across multiple PulseChain defi protocols.",
    //   image: "",
    //   dapplink: "",
    //   morelink:
    //     "https://docs.powercity.io/ecosystem/accelerator-yield-compounder",
    //   id: "accelerator",
    // },
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "80vw",
    },
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [modalLong, setmodalLong] = useState("");
  const [modalImage, setmodalImage] = useState("");
  const [modalDappLink, setmodalDappLink] = useState("");
  const [modalLaunchBtn, setmodalLaunchBtn] = useState("");
  const [modalExternalLink, setmodalExternalLink] = useState("");

  const closeModal = (e) => {
    setModalIsOpen(false);
    e.preventDefault();
  };

  const openModal = (i) => {
    setModalIsOpen(true);
    console.log(i);
    setmodalTitle(ServiceList[i].title);
    setmodalLong(ServiceList[i].long);
    setmodalImage(ServiceList[i].image);
    setmodalDappLink(ServiceList[i].dapplink);
    setmodalLaunchBtn(ServiceList[i].launchbtn);
    setmodalExternalLink(ServiceList[i].morelink);
  };

  let title = "POWERCITY Apps",
    description =
      "POWERCITY is an ecosystem of projects designed to improve PulseChain for the community.",
    subtitle = "All connected through its central CORE:";

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">{subtitle}</span>
              <h2 className="title">{title}</h2>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 mt--30">
            <div className="row service-main-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2 text-left">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>

                      <div className="button-group mt--0">
                        {val.dapplink && (
                          <a
                            target="_blank"
                            className="btn-default size-sm"
                            href={val.dapplink}>
                            {val.launchbtn}
                          </a>
                        )}
                        <button
                          className="btn-default btn-border size-sm"
                          onClick={(event) => {
                            openModal(i);
                          }}>
                          Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onClose={() => closeModal}
          ariaHideApp={false}
          //onAfterOpen={afterOpenModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={closeModal}
          closeTimeoutMS={200}>
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-6 col-12">
              <h2>{modalTitle}</h2>
              <p>{modalLong}</p>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-6 col-12">
              <img
                src={modalImage}
                alt={modalTitle}
                onError={(event) => (event.target.style.display = "none")}
              />
            </div>
          </div>
          <br />
          <div className="button-group mt--0">
            <a onClick={closeModal} href="#">
              <FiX />
            </a>
            {modalDappLink && (
              <a
                className="btn-default size-sm"
                target="_blank"
                href={modalDappLink}>
                {modalLaunchBtn}
              </a>
            )}
            <a
              className="btn-default btn-border size-sm"
              target="_blank"
              href={modalExternalLink}>
              Learn More
            </a>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ServiceTwo;
